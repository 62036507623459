@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
    font-family: 'JetBrains Mono', monospace;
    font-weight: lighter;
}

h1 {
    font-weight: bold;
}

h2 {
    padding-top: 30px;
}

span {
    font-weight: lighter;
}

.card {
    border: 0;
    box-shadow: 0.2px 0.4px 0.4px hsl(0deg 0% 64% / 0.82),
            0.6px 1.3px 1.2px -2.2px hsl(0deg 0% 64% / 0.58),
            3.2px 7px 6.5px -4.4px hsl(0deg 0% 64% / 0.35);
    box-shadow: var(--shadow-elevation-medium);
    margin: 14px;
    transition: all 0.3s ease;
}

.card:hover{
    box-shadow: 0.2px 0.4px 0.4px hsl(0deg 0% 64% / 0.76),
            0.5px 1.1px 1px -0.9px hsl(0deg 0% 64% / 0.65),
            1.5px 3.3px 3px -1.8px hsl(0deg 0% 64% / 0.54),
            3.8px 8.5px 7.8px -2.6px hsl(0deg 0% 64% / 0.43),
            8.3px 18.4px 17px -3.5px hsl(0deg 0% 64% / 0.32),
            15.6px 34.6px 31.9px -4.4px hsl(0deg 0% 64% / 0.21);
    box-shadow: var(--shadow-elevation-high);
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    transition: all 0.3s ease;
}

.navbar {
    padding-top: 10px;
    padding-bottom: 30px;
}

.button {
    height: 1000px;
}

.Footer {
    position: relative;
    padding-top: 100px;
    padding-bottom: 40px;
    height: 40px;
}

.mint-button {
    font-weight: bold;
    font-size: large;
    text-align: center;
    padding: 10px 17px;
    -webkit-border-radius: 10px;
}

.mint-button-inactive {
    text-align: center;
    padding: 10px;
    -webkit-border-radius: 10px;
}

.card-img-wrap {
    overflow: hidden;
    position: relative;
}

.card-img-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.card-img-wrap img {
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    width: 100%;
}


.label {
    min-width: 100px;
}

.hilight {
    background-color: yellow;
}


.section-header {
    font-size: medium;
    font-weight: bold;
    height: 20px;
    margin-bottom: 25px;
    margin-top: 30px;
}

.section-header span {

    
    padding: 2px 7px;
}

:root {
    --shadow-color: 0deg 0% 64%;
    --shadow-elevation-low:
            0.2px 0.4px 0.4px hsl(var(--shadow-color) / 0.88),
            0.6px 1.4px 1.3px -4.4px hsl(var(--shadow-color) / 0.5);
    --shadow-elevation-medium:
            0.2px 0.4px 0.4px hsl(var(--shadow-color) / 0.82),
            0.6px 1.3px 1.2px -2.2px hsl(var(--shadow-color) / 0.58),
            3.2px 7px 6.5px -4.4px hsl(var(--shadow-color) / 0.35);
    --shadow-elevation-high:
            0.2px 0.4px 0.4px hsl(var(--shadow-color) / 0.76),
            0.5px 1.1px 1px -0.9px hsl(var(--shadow-color) / 0.65),
            1.5px 3.3px 3px -1.8px hsl(var(--shadow-color) / 0.54),
            3.8px 8.5px 7.8px -2.6px hsl(var(--shadow-color) / 0.43),
            8.3px 18.4px 17px -3.5px hsl(var(--shadow-color) / 0.32),
            15.6px 34.6px 31.9px -4.4px hsl(var(--shadow-color) / 0.21);
}
